<script>
export default {
	name: 'Services'
}
</script>
<template>
	<section id="services" class="section border-b bg-web-desc"
		:style="{ 'background-image': 'url(' + require('@/assets/images/bg/pattern4.png') + ')', 'background-size': 'contain', 'background-position': 'top center' }">
		<div class="container">
			<div class="row margin-top-30">
				<div class="col-lg-8 offset-lg-2">
					<p class="section-title text-center">产品 & 服务</p>
					<div class="section-title-border"></div>
					<p class="black7 text-center font-11 padding-top-12">PRODUCTS AND SERVICES</p>
				</div>
			</div>
			<div class="row margin-t-30">
				<div class="col-lg-4 margin-t-20 boxitem">
					<div class="services-box">
						<div class="media">
							<img src="@/assets/images/bg/dspt.png" class="navimg" />
							<div class="media-body ml-4">
								<p class="font-18 black8">电商平台</p>
								<p class="pt-2">纪元电商平台为您实现跨境电商所需全流程对接服务</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 margin-t-20 boxitem">
					<div class="services-box">
						<div class="media">
							<img src="@/assets/images/bg/rjkf.png" class="navimg" />
							<div class="media-body ml-4">
								<p class="font-18 black8">软件开发</p>
								<p class="pt-2">专业技术团队为客户提供企业级软件开发与运维服务</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 margin-t-20 boxitem">
					<div class="services-box">
						<div class="media">
							<img src="@/assets/images/bg/yjs.png" class="navimg" />
							<div class="media-body ml-4">
								<p class="font-18 black8">云计算</p>
								<p class="pt-2">中金云计算为客户提供全方位多场景互联网数据服务</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<style>
.navimg {
	width: 120px;
}
.boxitem:hover{
	box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.12);
}
</style>
